import { CSSProperties } from 'react';

export function TrustWalletLogo({ size = 16, style }: { size?: number; style?: CSSProperties }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 2000 2000">
			<circle cx="1000" cy="1000" r="1000" fill="#3375bb" />
			<path
				d="M1490.78 504.57a65.1 65.1 0 0 1 65 65.94c-3.12 186.3-10.31 328.8-23.75 441.6-13.1 112.8-32.81 196.6-62.5 264.4-20 45.3-44.69 82.8-73.7 114.7-39.1 42.2-83.7 72.82-132.5 101.9-20.84 12.46-42.51 24.77-65.2 37.68-48.5 27.54-101.8 57.83-162.3 98.3a64.66 64.66 0 0 1 -72.18 0c-61.4-40.91-115.4-71.6-164.3-99.3q-16.32-9.25-31.92-18.2c-57.2-33.1-108.7-64.7-153.7-110.3-30-30-55.94-66.6-76.6-110-28.1-58.1-47.2-128.4-61.2-219.4C457 950.2 447.7 791.1 444.2 570.5A65.13 65.13 0 0 1 462.66 524a66.25 66.25 0 0 1 46.56 -19.38H536.1c82.8.31 265.6-7.81 423.7-130.9a65.21 65.21 0 0 1 79.69 0c158.1 123.1 340.9 131.3 424.1 130.9m-118.1 730.3c20.31-41.87 37.2-99.7 50-182.8 15.31-99.4 24.69-234.4 29.1-418.1-97.5-2.82-265-21.57-424.7-129.1C840.2 612.1 672.7 630.8 575.5 634c3.44 151.9 10.31 270 21.25 362.8 12.5 105.6 30.3 177.2 52.5 227.5 14.69 33.44 30.94 57.5 50.3 78.8 25.94 28.44 58.75 51.87 103.4 78.8 18.54 11.1 39 22.69 61.2 35.3 39.64 22.43 85 48.1 135.6 80.3 49.71-31.7 94.4-57.1 133.6-79.4 11.81-6.72 23.1-13.1 33.89-19.37 55-31.56 95.6-57.81 125.9-88.4C1313.6 1289.3 1330.2 1266.5 1345.5 1234.9"
				fill="#fff"
				fillRule="evenodd"
			/>
		</svg>
	);
}
