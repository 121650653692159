import { CSSProperties } from 'react';

export function YlideLargeLogo({ className, style }: { className?: string; style?: CSSProperties }) {
	return (
		<svg
			className={className}
			style={style}
			width="174"
			height="42"
			viewBox="0 0 174 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M32.58 42L35.0284 36.4467L35.0515 38.3054L28.9998 24.8869H31.7716L35.5597 33.6361C35.6367 33.7872 35.7291 34.0214 35.8368 34.3388C35.9446 34.641 36.037 34.9432 36.114 35.2454L35.5135 35.3814C35.6367 35.0641 35.7522 34.7468 35.8599 34.4294C35.9831 34.1121 36.0986 33.7948 36.2064 33.4774L39.3708 24.8869H42.1888L37.1996 36.7867L35.1439 42H32.58Z"
				fill="#202020"
			/>
			<path d="M43.5883 36.7867V20.0136H45.9674V36.7867H43.5883Z" fill="#202020" />
			<path
				d="M48.0764 36.7867V24.8869H50.4555V36.7867H48.0764ZM49.2313 22.9466C48.7232 22.9466 48.3305 22.8182 48.0534 22.5613C47.7762 22.3044 47.6376 21.9418 47.6376 21.4733C47.6376 21.0351 47.7762 20.68 48.0534 20.408C48.3459 20.136 48.7386 20 49.2313 20C49.7395 20 50.1322 20.1284 50.4093 20.3853C50.6865 20.6422 50.8251 21.0049 50.8251 21.4733C50.8251 21.9115 50.6788 22.2666 50.3863 22.5386C50.1091 22.8106 49.7241 22.9466 49.2313 22.9466Z"
				fill="#202020"
			/>
			<path
				d="M58.1007 37.0134C56.992 37.0134 55.9988 36.749 55.121 36.2201C54.2587 35.6761 53.5735 34.9432 53.0653 34.0214C52.5572 33.0846 52.3031 32.0192 52.3031 30.8255C52.3031 29.6317 52.5495 28.5739 53.0422 27.6522C53.5504 26.7153 54.2356 25.9824 55.0979 25.4535C55.9603 24.9095 56.9381 24.6375 58.0314 24.6375C58.6319 24.6375 59.2094 24.7358 59.7638 24.9322C60.3335 25.1135 60.8417 25.3704 61.2882 25.7029C61.7348 26.0202 62.4031 26.3753 62.6495 26.7682C62.9112 27.146 63.0421 27.5313 63.0421 27.9242L62.0274 27.9695L62.3199 20.0136H64.7283V36.7867H62.3199L62.0274 33.9534H62.8111C62.8111 34.3161 62.688 34.6788 62.4416 35.0414C62.1952 35.389 61.5423 35.7139 61.1265 36.0161C60.7262 36.3183 60.2565 36.5601 59.7176 36.7414C59.194 36.9227 58.655 37.0134 58.1007 37.0134ZM58.4241 34.9961C59.1478 34.9961 59.6696 34.8401 60.3412 34.4521C61.0481 34.0437 61.4082 33.6805 61.8102 32.9788C62.2416 32.2259 62.3245 31.6264 62.3245 30.8255C62.3245 30.0246 62.2333 29.4424 61.8102 28.6948C61.4088 27.9855 61.0525 27.6124 60.3412 27.1989C59.6707 26.8091 59.1478 26.6549 58.4241 26.6549C57.7003 26.6549 57.0613 26.8362 56.5069 27.1989C55.9526 27.5615 55.5137 28.0602 55.1903 28.6948C54.8824 29.3144 54.7284 30.0246 54.7284 30.8255C54.7284 31.6264 54.8824 32.3441 55.1903 32.9788C55.5137 33.5983 55.9526 34.0894 56.5069 34.4521C57.0613 34.8148 57.7003 34.9961 58.4241 34.9961Z"
				fill="#202020"
			/>
			<path
				d="M72.7092 37.0134C71.4619 37.0134 70.3532 36.7565 69.3831 36.2427C68.4283 35.7139 67.6738 34.9961 67.1194 34.0894C66.5805 33.1828 66.311 32.1401 66.311 30.9615C66.311 30.0246 66.465 29.1708 66.773 28.4002C67.0809 27.6295 67.5044 26.9646 68.0434 26.4055C68.5977 25.8313 69.2522 25.3931 70.0067 25.0909C70.7766 24.7735 71.6082 24.6149 72.5013 24.6149C73.2866 24.6149 74.0181 24.766 74.6956 25.0682C75.3731 25.3553 75.9583 25.7558 76.4511 26.2695C76.9592 26.7833 77.3442 27.3953 77.606 28.1055C77.8831 28.8006 78.014 29.5637 77.9986 30.3948L77.9755 31.3921H68.0665L67.5352 29.5335H75.9198L75.5733 29.9188V29.3748C75.5271 28.8762 75.3577 28.4304 75.0652 28.0375C74.7726 27.6446 74.403 27.3348 73.9565 27.1082C73.5099 26.8815 73.0248 26.7682 72.5013 26.7682C71.6698 26.7682 70.9691 26.9269 70.3994 27.2442C69.8296 27.5464 69.3985 27.9997 69.1059 28.6042C68.8133 29.1935 68.667 29.9264 68.667 30.8028C68.667 31.6339 68.8441 32.3592 69.1983 32.9788C69.5524 33.5832 70.0529 34.0517 70.6996 34.3841C71.3464 34.7165 72.0932 34.8828 72.9401 34.8828C73.5407 34.8828 74.0951 34.7845 74.6032 34.5881C75.1268 34.3916 75.6888 34.0365 76.2894 33.5228L77.4905 35.1774C77.1209 35.5401 76.6666 35.8574 76.1277 36.1294C75.6041 36.4014 75.0421 36.6205 74.4415 36.7867C73.8564 36.9378 73.2789 37.0134 72.7092 37.0134Z"
				fill="#202020"
			/>
			<path
				d="M0 10.4643C0 8.79078 1.35662 7.43416 3.0301 7.43416H3.23807C4.91155 7.43416 6.26817 8.79078 6.26817 10.4643C6.26817 12.1377 4.91154 13.4944 3.23807 13.4944H3.0301C1.35662 13.4944 0 12.1377 0 10.4643Z"
				fill="#202020"
			/>
			<path
				d="M7.66391 3.0301C7.66391 1.35662 9.02053 0 10.694 0H10.902C12.5755 0 13.9321 1.35662 13.9321 3.0301C13.9321 4.70358 12.5755 6.0602 10.902 6.0602H10.694C9.02053 6.0602 7.66391 4.70358 7.66391 3.0301Z"
				fill="#202020"
			/>
			<path
				d="M23.0425 3.0301C23.0425 1.35662 24.3991 0 26.0726 0H26.2806C27.954 0 29.3107 1.35662 29.3107 3.0301C29.3107 4.70358 27.954 6.0602 26.2806 6.0602H26.0726C24.3991 6.0602 23.0425 4.70358 23.0425 3.0301Z"
				fill="#202020"
			/>
			<path
				d="M30.7064 10.4643C30.7064 8.79078 32.063 7.43416 33.7365 7.43416H33.9445C35.6179 7.43416 36.9746 8.79078 36.9746 10.4643C36.9746 12.1377 35.6179 13.4944 33.9445 13.4944H33.7365C32.063 13.4944 30.7064 12.1377 30.7064 10.4643Z"
				fill="#202020"
			/>
			<path
				d="M23.0425 17.9475C23.0425 16.274 24.3991 14.9174 26.0726 14.9174H26.2806C27.954 14.9174 29.3107 16.274 29.3107 17.9475C29.3107 19.621 27.954 20.9776 26.2806 20.9776H26.0726C24.3991 20.9776 23.0425 19.621 23.0425 17.9475Z"
				fill="#202020"
			/>
			<path
				d="M15.3532 10.4643C15.3532 8.79078 16.7098 7.43416 18.3833 7.43416H18.5913C20.2647 7.43416 21.6214 8.79078 21.6214 10.4643C21.6214 12.1377 20.2647 13.4944 18.5913 13.4944H18.3833C16.7098 13.4944 15.3532 12.1377 15.3532 10.4643Z"
				fill="#202020"
			/>
			<path
				d="M15.3532 25.259C15.3532 23.5855 16.7098 22.2289 18.3833 22.2289H18.5913C20.2647 22.2289 21.6214 23.5855 21.6214 25.259C21.6214 26.9325 20.2647 28.2891 18.5913 28.2891H18.3833C16.7098 28.2891 15.3532 26.9325 15.3532 25.259Z"
				fill="#202020"
			/>
			<path
				d="M7.66391 17.9475C7.66391 16.274 9.02053 14.9174 10.694 14.9174H10.902C12.5755 14.9174 13.9321 16.274 13.9321 17.9475C13.9321 19.621 12.5755 20.9776 10.902 20.9776H10.694C9.02053 20.9776 7.66391 19.621 7.66391 17.9475Z"
				fill="#202020"
			/>
			<path
				d="M16.9671 7.81382L13.5626 4.52007V4.45854C13.2683 4.98993 12.8149 5.4266 12.2639 5.70901H12.3331L15.7365 9.00172V9.01095C16.0213 8.50824 16.4491 8.09212 16.9671 7.81382Z"
				fill="#202020"
			/>
			<path
				d="M23.4355 4.52007V4.50041C23.7302 5.01287 24.1742 5.43405 24.7106 5.70901H24.665L21.2616 9.00172V9.05348C20.9791 8.53515 20.5457 8.10563 20.0179 7.81951H20.0251L23.4355 4.52007Z"
				fill="#202020"
			/>
			<path
				d="M17.6304 13.3797V22.3435C17.9027 22.2689 18.1902 22.2289 18.4873 22.2289C18.7936 22.2289 19.0897 22.2714 19.3695 22.3506V13.3727C19.0897 13.4519 18.7936 13.4944 18.4873 13.4944C18.1902 13.4944 17.9027 13.4544 17.6304 13.3797Z"
				fill="#202020"
			/>
			<path
				d="M16.8949 22.6486L13.5522 19.4163V19.3946C13.2615 19.9107 12.8202 20.336 12.2853 20.6153H12.3331L15.6998 23.8725C15.9711 23.3637 16.3869 22.9389 16.8949 22.6486Z"
				fill="#202020"
			/>
			<path
				d="M8.02885 16.4532V16.5271C8.32109 15.9957 8.7722 15.5583 9.32095 15.2743H9.26708L5.8534 11.9717C5.55241 12.4782 5.1044 12.8926 4.56569 13.1605H4.6237L8.02885 16.4532Z"
				fill="#202020"
			/>
			<path
				d="M17.6304 28.1745V36.9746H19.3695V28.1674C19.0897 28.2466 18.7936 28.2891 18.4873 28.2891C18.1902 28.2891 17.9027 28.2491 17.6304 28.1745Z"
				fill="#202020"
			/>
			<path
				d="M21.2821 23.8865L24.672 20.6086H24.6764C24.1572 20.3344 23.727 19.9223 23.4387 19.4233L20.0944 22.6571C20.6003 22.9498 21.0137 23.3765 21.2821 23.8865Z"
				fill="#202020"
			/>
			<path
				d="M28.9658 16.4565V16.5644C28.6721 16.0119 28.208 15.558 27.6405 15.2676H27.7363L31.1298 11.9862C31.4307 12.4861 31.8752 12.8951 32.4088 13.1605H32.3744L28.9658 16.4565Z"
				fill="#202020"
			/>
			<path
				d="M86 30.5C86 25.2533 90.2166 21 95.418 21H156.582C161.783 21 166 25.2533 166 30.5V30.5C166 35.7467 161.783 40 156.582 40H95.418C90.2166 40 86 35.7467 86 30.5V30.5Z"
				fill="url(#paint0_radial_413_8659)"
			/>
			<path
				d="M86 30.5C86 25.2533 90.2166 21 95.418 21H156.582C161.783 21 166 25.2533 166 30.5V30.5C166 35.7467 161.783 40 156.582 40H95.418C90.2166 40 86 35.7467 86 30.5V30.5Z"
				fill="url(#paint1_radial_413_8659)"
			/>
			<path
				d="M96.6764 35.214C96.1444 35.214 95.6575 35.1479 95.2156 35.0158C94.7819 34.8838 94.3932 34.6898 94.0495 34.4339C93.714 34.178 93.4112 33.8684 93.1411 33.5052L94.295 32.1804C94.696 32.7417 95.0929 33.1255 95.4857 33.3319C95.8867 33.53 96.3204 33.6291 96.7868 33.6291C97.0405 33.6291 97.2656 33.596 97.462 33.53C97.6665 33.4557 97.8261 33.3525 97.9407 33.2204C98.0553 33.0884 98.1125 32.9315 98.1125 32.7499C98.1125 32.6179 98.0839 32.4982 98.0266 32.3909C97.9693 32.2753 97.8875 32.1762 97.7811 32.0937C97.6747 32.0029 97.5479 31.9245 97.4006 31.8584C97.2533 31.7841 97.0896 31.7222 96.9096 31.6727C96.7295 31.6149 96.5331 31.5695 96.3204 31.5365C95.8621 31.4292 95.4611 31.3012 95.1174 31.1526C94.7737 30.9958 94.4873 30.806 94.2582 30.5831C94.029 30.3519 93.8572 30.0919 93.7426 29.803C93.6362 29.5141 93.583 29.188 93.583 28.8248C93.583 28.4534 93.6649 28.1108 93.8285 27.7971C93.9922 27.4752 94.2172 27.1987 94.5037 26.9675C94.7983 26.7364 95.1338 26.5589 95.5102 26.4351C95.8948 26.3113 96.2999 26.2494 96.7255 26.2494C97.2492 26.2494 97.7075 26.3113 98.1003 26.4351C98.4931 26.5507 98.8327 26.7199 99.1191 26.9428C99.4137 27.1656 99.6592 27.438 99.8556 27.76L98.6895 28.8991C98.5176 28.668 98.3294 28.474 98.1248 28.3172C97.9284 28.1603 97.7116 28.0448 97.4742 27.9705C97.2451 27.8879 97.0078 27.8466 96.7623 27.8466C96.4922 27.8466 96.259 27.8838 96.0626 27.9581C95.8662 28.0241 95.7107 28.1232 95.5961 28.2553C95.4898 28.3873 95.4366 28.5483 95.4366 28.7382C95.4366 28.8867 95.4734 29.0188 95.547 29.1344C95.6207 29.2499 95.7271 29.3531 95.8662 29.4439C96.0053 29.5265 96.1731 29.6008 96.3695 29.6668C96.5659 29.7328 96.7827 29.7906 97.0201 29.8402C97.4701 29.931 97.8752 30.0506 98.2353 30.1992C98.5954 30.3478 98.9022 30.5294 99.1559 30.744C99.4178 30.9504 99.6183 31.198 99.7574 31.487C99.8965 31.7676 99.9661 32.0854 99.9661 32.4404C99.9661 33.0265 99.827 33.5259 99.5487 33.9386C99.2705 34.3513 98.8859 34.6691 98.3949 34.892C97.9039 35.1066 97.331 35.214 96.6764 35.214Z"
				fill="#202020"
			/>
			<path
				d="M104.408 35.214C103.745 35.214 103.152 35.0695 102.628 34.7806C102.113 34.4834 101.703 34.0789 101.401 33.5671C101.106 33.0554 100.959 32.4693 100.959 31.8089C100.959 31.1485 101.106 30.5666 101.401 30.063C101.703 29.5512 102.113 29.1468 102.628 28.8496C103.152 28.5524 103.745 28.4038 104.408 28.4038C105.063 28.4038 105.648 28.5524 106.163 28.8496C106.687 29.1468 107.096 29.5512 107.391 30.063C107.685 30.5666 107.833 31.1485 107.833 31.8089C107.833 32.4693 107.685 33.0554 107.391 33.5671C107.096 34.0789 106.687 34.4834 106.163 34.7806C105.648 35.0695 105.063 35.214 104.408 35.214ZM104.408 33.6786C104.727 33.6786 105.014 33.6002 105.267 33.4433C105.521 33.2782 105.717 33.0554 105.856 32.7747C106.004 32.4858 106.077 32.1638 106.077 31.8089C106.077 31.4457 106.004 31.1238 105.856 30.8431C105.717 30.5542 105.521 30.3313 105.267 30.1745C105.014 30.0094 104.727 29.9268 104.408 29.9268C104.081 29.9268 103.79 30.0094 103.536 30.1745C103.283 30.3396 103.082 30.5624 102.935 30.8431C102.788 31.1238 102.718 31.4457 102.726 31.8089C102.718 32.1638 102.788 32.4858 102.935 32.7747C103.082 33.0554 103.283 33.2782 103.536 33.4433C103.79 33.6002 104.081 33.6786 104.408 33.6786Z"
				fill="#202020"
			/>
			<path
				d="M111.967 35.214C111.345 35.214 110.789 35.0654 110.298 34.7682C109.807 34.471 109.418 34.0666 109.132 33.5548C108.854 33.043 108.715 32.461 108.715 31.8089C108.715 31.165 108.854 30.5872 109.132 30.0754C109.418 29.5554 109.807 29.1468 110.298 28.8496C110.789 28.5524 111.345 28.4038 111.967 28.4038C112.548 28.4038 113.08 28.5112 113.563 28.7258C114.046 28.9404 114.422 29.2376 114.693 29.6173L113.735 30.7812C113.62 30.6243 113.477 30.484 113.305 30.3602C113.134 30.2281 112.945 30.1249 112.741 30.0506C112.544 29.9764 112.34 29.9392 112.127 29.9392C111.791 29.9392 111.493 30.0218 111.231 30.1869C110.977 30.3437 110.777 30.5624 110.629 30.8431C110.482 31.1238 110.408 31.4457 110.408 31.8089C110.408 32.1638 110.482 32.4817 110.629 32.7623C110.785 33.0347 110.994 33.2576 111.255 33.4309C111.517 33.596 111.812 33.6786 112.139 33.6786C112.352 33.6786 112.553 33.6497 112.741 33.5919C112.929 33.5259 113.105 33.4309 113.269 33.3071C113.44 33.1833 113.596 33.0347 113.735 32.8614L114.68 34.0253C114.402 34.3802 114.017 34.6691 113.526 34.892C113.035 35.1066 112.516 35.214 111.967 35.214Z"
				fill="#202020"
			/>
			<path
				d="M115.944 35.0901V28.54H117.688V35.0901H115.944ZM116.804 27.1904C116.468 27.1904 116.206 27.1079 116.018 26.9428C115.83 26.7777 115.736 26.5424 115.736 26.237C115.736 25.9563 115.83 25.7293 116.018 25.556C116.215 25.3744 116.476 25.2836 116.804 25.2836C117.139 25.2836 117.401 25.3702 117.589 25.5436C117.778 25.7087 117.872 25.9398 117.872 26.237C117.872 26.5259 117.773 26.757 117.577 26.9304C117.389 27.1037 117.131 27.1904 116.804 27.1904Z"
				fill="#202020"
			/>
			<path
				d="M121.98 35.214C121.44 35.214 120.953 35.0654 120.519 34.7682C120.085 34.471 119.742 34.0666 119.488 33.5548C119.234 33.043 119.107 32.4569 119.107 31.7965C119.107 31.1361 119.234 30.5542 119.488 30.0506C119.75 29.5389 120.102 29.1385 120.544 28.8496C120.985 28.5524 121.489 28.4038 122.053 28.4038C122.373 28.4038 122.663 28.4534 122.925 28.5524C123.195 28.6432 123.428 28.7712 123.625 28.9363C123.829 29.1014 124.001 29.2912 124.14 29.5058C124.279 29.7205 124.377 29.9516 124.435 30.1992L124.066 30.1373V28.54H125.797V35.0901H124.042V33.5176L124.435 33.4805C124.369 33.7116 124.263 33.9303 124.116 34.1367C123.968 34.3431 123.784 34.5288 123.563 34.6939C123.35 34.8507 123.109 34.9787 122.839 35.0777C122.569 35.1686 122.282 35.214 121.98 35.214ZM122.458 33.691C122.786 33.691 123.072 33.6125 123.318 33.4557C123.563 33.2989 123.751 33.0801 123.882 32.7995C124.021 32.5105 124.091 32.1762 124.091 31.7965C124.091 31.4251 124.021 31.099 123.882 30.8183C123.751 30.5377 123.563 30.3189 123.318 30.1621C123.072 29.997 122.786 29.9144 122.458 29.9144C122.139 29.9144 121.857 29.997 121.611 30.1621C121.374 30.3189 121.186 30.5377 121.047 30.8183C120.908 31.099 120.838 31.4251 120.838 31.7965C120.838 32.1762 120.908 32.5105 121.047 32.7995C121.186 33.0801 121.374 33.2989 121.611 33.4557C121.857 33.6125 122.139 33.691 122.458 33.691Z"
				fill="#202020"
			/>
			<path d="M127.557 35.0901V25.9274H129.3V35.0901H127.557Z" fill="#202020" />
			<path
				d="M140.492 35.0901V26.4227H142.333V35.0901H140.492ZM135.079 35.0901V26.4227H136.92V35.0901H135.079ZM135.815 31.6108L135.827 29.9268H141.437V31.6108H135.815Z"
				fill="#202020"
			/>
			<path
				d="M146.561 35.214C146.087 35.214 145.678 35.1108 145.334 34.9044C144.998 34.6898 144.736 34.3885 144.548 34.0005C144.368 33.6043 144.278 33.1296 144.278 32.5766V28.54H146.021V32.2918C146.021 32.589 146.07 32.849 146.169 33.0719C146.267 33.2865 146.406 33.4516 146.586 33.5671C146.766 33.6827 146.987 33.7405 147.249 33.7405C147.437 33.7405 147.613 33.7116 147.777 33.6538C147.94 33.5878 148.079 33.497 148.194 33.3814C148.308 33.2658 148.399 33.1296 148.464 32.9728C148.529 32.816 148.562 32.6467 148.562 32.4651V28.54H150.305V35.0901H148.648L148.587 33.7405L148.906 33.5919C148.808 33.8973 148.644 34.1739 148.415 34.4215C148.186 34.6691 147.912 34.8631 147.592 35.0035C147.273 35.1438 146.93 35.214 146.561 35.214Z"
				fill="#202020"
			/>
			<path
				d="M155.803 35.214C155.517 35.214 155.238 35.1727 154.968 35.0901C154.698 35.0076 154.453 34.8961 154.232 34.7558C154.011 34.6072 153.827 34.4421 153.679 34.2605C153.532 34.0707 153.438 33.8767 153.397 33.6786L153.79 33.53L153.704 35.0654H152.059V25.9274H153.802V30.0754L153.471 29.9392C153.52 29.7411 153.614 29.5512 153.753 29.3696C153.892 29.1798 154.068 29.0147 154.281 28.8744C154.494 28.7258 154.727 28.6102 154.981 28.5277C155.234 28.4451 155.496 28.4038 155.766 28.4038C156.331 28.4038 156.834 28.5524 157.276 28.8496C157.718 29.1385 158.066 29.5389 158.319 30.0506C158.581 30.5624 158.712 31.1485 158.712 31.8089C158.712 32.4693 158.585 33.0554 158.332 33.5671C158.078 34.0789 157.73 34.4834 157.288 34.7806C156.855 35.0695 156.36 35.214 155.803 35.214ZM155.386 33.7157C155.713 33.7157 155.995 33.6373 156.233 33.4805C156.478 33.3154 156.671 33.0925 156.81 32.8118C156.949 32.5229 157.018 32.1886 157.018 31.8089C157.018 31.4374 156.949 31.1072 156.81 30.8183C156.679 30.5294 156.49 30.3065 156.245 30.1497C155.999 29.9929 155.713 29.9144 155.386 29.9144C155.058 29.9144 154.772 29.9929 154.526 30.1497C154.281 30.3065 154.089 30.5294 153.95 30.8183C153.81 31.1072 153.741 31.4374 153.741 31.8089C153.741 32.1886 153.81 32.5229 153.95 32.8118C154.089 33.0925 154.281 33.3154 154.526 33.4805C154.772 33.6373 155.058 33.7157 155.386 33.7157Z"
				fill="#202020"
			/>
			<path
				d="M167.935 24.64V16.181C167.935 15.5503 168.045 15.048 168.265 14.674C168.492 14.2926 168.797 14.0176 169.178 13.849C169.559 13.673 169.985 13.585 170.454 13.585C170.953 13.585 171.374 13.6656 171.719 13.827C172.071 13.9883 172.335 14.2266 172.511 14.542C172.694 14.85 172.786 15.2313 172.786 15.686C172.786 16.038 172.713 16.3423 172.566 16.599C172.427 16.8483 172.24 17.0536 172.005 17.215C171.77 17.369 171.51 17.4753 171.224 17.534V17.578C171.598 17.6293 171.928 17.743 172.214 17.919C172.5 18.095 172.724 18.337 172.885 18.645C173.054 18.9456 173.138 19.3196 173.138 19.767C173.138 20.2656 173.024 20.691 172.797 21.043C172.577 21.395 172.273 21.6626 171.884 21.846C171.503 22.022 171.07 22.11 170.586 22.11C170.322 22.11 170.091 22.0953 169.893 22.066C169.702 22.0366 169.53 21.9926 169.376 21.934C169.222 21.8753 169.064 21.8093 168.903 21.736V24.64H167.935ZM170.498 21.318C171.011 21.318 171.418 21.1786 171.719 20.9C172.02 20.614 172.17 20.2143 172.17 19.701C172.17 19.3123 172.093 18.9933 171.939 18.744C171.792 18.4873 171.594 18.2966 171.345 18.172C171.103 18.04 170.843 17.974 170.564 17.974H169.651V17.226H170.454C170.733 17.226 170.971 17.1636 171.169 17.039C171.374 16.9143 171.528 16.7383 171.631 16.511C171.741 16.2836 171.796 16.0233 171.796 15.73C171.796 15.2753 171.66 14.938 171.389 14.718C171.125 14.498 170.806 14.388 170.432 14.388C170.175 14.388 169.93 14.4393 169.695 14.542C169.46 14.6373 169.27 14.817 169.123 15.081C168.976 15.345 168.903 15.7263 168.903 16.225V20.867C169.16 20.999 169.405 21.109 169.64 21.197C169.875 21.2776 170.161 21.318 170.498 21.318Z"
				fill="black"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_413_8659"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(167.178 43.5185) rotate(-164.44) scale(85.4222 112.99)"
				>
					<stop stopColor="#FFB571" />
					<stop offset="1" stopColor="#FFB571" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_413_8659"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(79.5843 17.5661) rotate(28.5823) scale(65.9785 87.0356)"
				>
					<stop stopColor="#97A1FF" />
					<stop offset="1" stopColor="#97A1FF" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
}
